<template>
  <div class="detail page_min_width">
    <detail-header title="挂靠审核" />
    <div class="detail_main page_width" v-loading="loading">
      <div class="main_content">
        <info-header title="基本信息" />
        <div style="padding: 20px 0;">
          <info-item
            :label-width="labelWidth"
            label="头像"
            value-type="image"
            :value="formData.avatarUrl">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="姓名"
            :value="formData.username">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="性别"
            :value="{
              [globalVar.USER_GENDER_0]: '未知',
              [globalVar.USER_GENDER_1]: '男',
              [globalVar.USER_GENDER_2]: '女'
            }[formData.gender]">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="身份证号"
            :value="formData.idCard">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="出生年月日"
            :value="formData.birthDate">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="身份类型"
            :value="formData.identityCategory">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="行业领域"
            :value="getIndustrySectorNames(formData.serviceTrade)">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="产业特长"
            :value="getTechnicalNames(formData.industryExpertise)">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="服务方向"
            :value="formData.serviceDirection">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="工作单位"
            :value="formData.workUnit">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="职务"
            :value="formData.jobTitle">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="最高学历"
            :value="formData.highestEducation">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="联系手机"
            :value="formData.contactNumber">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="联系邮箱"
            :value="formData.contactEmail">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="联系地址"
            :value="contactAddress">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="服务案例"
            :value="formData.serviceCase">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="个人简介"
            :value="formData.personalProfile">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="职称/所获资格认证"
            :value="formData.positionalTitleText">
          </info-item>
        </div>
        <!-- 挂靠用户处于待处理状态 并且 当前用户角色是法人类型的机构 (机构角色， 0 - 挂靠关系 / 1 - 法人关系) -->
        <div
          v-if="formData.dependStatus === globalVar.DEPEND_STATUS_PENDING && userInfo.organizationRole"
          :style="{
            paddingLeft: labelWidth,
            marginTop: '20px'
          }">
          <el-button
            type="primary"
            size="small"
            :loading="btnLoading"
            @click="onAudit(2)">
            通过
          </el-button>
          <el-button
            type="danger"
            size="small"
            plain
            :loading="btnLoading"
            @click="onAudit(3)">
            驳回
          </el-button>
        </div>

        <!-- 被驳回 -->
        <template v-if="formData.dependStatus === globalVar.DEPEND_STATUS_REJECT && userInfo.id === formData.id">
          <info-header title="驳回信息" />
          <div style="padding: 20px 0;">
            <info-item
            :label-width="labelWidth"
            label="驳回原因"
            :value="formData.reason">
          </info-item>
          <!-- 已驳回状态下显示重新提交按钮 -->
          <el-row>
            <el-button
              type="primary"
              size="small"
              @click="selectIdentityVisible = true">
              重新申请
            </el-button>
          </el-row>
          </div>
        </template>
      </div>
    </div>

    <select-identity
      :visible.sync="selectIdentityVisible">
    </select-identity>
  </div>
</template>

<script>
import * as api from '@/api'
import cities from '@/assets/json/cities.json'
import globalVar from '@/configs/globalVar'
import selectIdentity from '@/views/personal-info/select-identity.vue'
export default {
  components: {
    selectIdentity
  },
  data () {
    return {
      loading: false,
      selectIdentityVisible: false,
      labelWidth: '120px',
      formData: {},
      btnLoading: false
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    // 技术领域
    technicalList () {
      return this.$store.state.technicalList
    },
    getTechnicalNames () {
      return (technical) => {
        const ids = technical ? technical.split(',') : []
        let result = ''

        this.technicalList.forEach(item => {
          if (ids.includes(item.id)) {
            result += result === '' ? item.name : ',' + item.name
          }
        })
        return result
      }
    },
    // 行业领域下拉列表
    industrySectorList () {
      return this.$store.state.industrySectorList
    },
    // 拿到行业领域的id字符串
    getIndustrySectorNames () {
      return (idList = []) => {
        let result = ''

        this.industrySectorList.forEach(item => {
          if (idList.includes(item.id)) {
            result += result === '' ? item.name : ',' + item.name
          }
        })
        return result
      }
    },

    // 联系地址
    contactAddress () {
      const { addressProvince, addressCity, addressCounty, contactAddress } = this.formData

      if (!addressProvince && !addressCity && !addressCounty) return ''

      const one = cities.find(item => {
        return item.value === addressProvince
      })
      if (!one) {
        return ''
      }
      const two = one.children.find(item => {
        return item.value === addressCity
      })
      if (!two) {
        return ''
      }
      const three = two.children.find(item => {
        return item.value === addressCounty
      })
      if (!three) {
        return ''
      }

      return `${one.label}、${two.label}、${three.label}、${contactAddress}`
    }
  },
  created () {
    this.getAuditDetail()
  },
  methods: {
    // 获取账号详情
    getAuditDetail () {
      this.loading = true
      api.getUserItemInfo(this.id).then(res => {
        if (res.data.code === 0) {
          this.formData = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    onAudit (logicStatus) {
      // 如果是已拒绝 则需要填写拒绝理由
      if (logicStatus === globalVar.DEPEND_STATUS_REJECT) {
        this.$prompt('请输入驳回原因', '提示', {
          confirmButtonText: '驳回',
          cancelButtonText: '取消',
          inputPattern: /\S/,
          inputErrorMessage: '请输入驳回原因',
          inputPlaceholder: '请输入驳回原因'
        }).then(({ value }) => {
          this.auditConfirm('', '', logicStatus, value)
        }).catch(() => {
        })
      } else {
        this.$msgbox({
          title: '确认',
          message: '请确认你的操作',
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              this.auditConfirm(instance, done, logicStatus)
            } else {
              done()
            }
          }
        })
      }
    },
    /**
     * 状态(审核)， 2 - 已通过, 3 - 已驳回
     */
    auditConfirm (instance, done, logicStatus, reason = '') {
      if (instance) {
        instance.confirmButtonLoading = true
      }
      this.btnLoading = true
      api.auditGkDetail(this.id, {
        logicStatus,
        reason
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
          this.getAuditDetail()
        } else {
          this.$message.error(res.data.message)
        }
        done && done()
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
        if (instance) {
          instance.confirmButtonLoading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 560px;
      margin: 0 auto;
    }
  }
}
</style>
